export const BE = [
    {
        name:'NPM',
        icon:'https://img.icons8.com/color/70/000000/npm.png'
    },
    {
        name:'Node',
        icon:'https://img.icons8.com/color/70/000000/nodejs.png'
    },
    {
        name:'API',
        icon:'https://img.icons8.com/color/70/000000/api.png'
    },
    {
        name:'Express',
        icon:'https://img.icons8.com/color/SDVmtZ6VBGXt/express-js.png'
    },
    {
        name:'Python',
        icon:'https://img.icons8.com/color/70/000000/python.png'
    },

]
export const FE = [
    {
        name:'Material UI',
        icon:'https://img.icons8.com/color/70/000000/material-ui.png'
    },
    {
        name:'Bootstrap',
        icon:'https://img.icons8.com/color/70/000000/bootstrap.png'
    },
    {
        name:'React',
        icon:'https://img.icons8.com/color/70/000000/react-native.png'
    },
    {
        name:'jQuery',
        icon:'https://img.icons8.com/ios-filled/70/000000/jquery.png'
    },
    {
        name:'JavaScript',
        icon:'https://img.icons8.com/color/70/000000/javascript.png'
    },
    {
        name:'CSS',
        icon:'https://img.icons8.com/color/70/000000/css3.png'
    },
    {
        name:'HTML',
        icon:'https://img.icons8.com/color/70/000000/html-5.png'
    },
    {
        name:'TypeScript',
        icon:'https://img.icons8.com/color/70/000000/typescript.png'
    },
]

export const DB = [
    {
        name:'MySQL',
        icon:'https://img.icons8.com/color/70/000000/mysql.png'
    },
    {
        name:'SQL',
        icon:'https://img.icons8.com/color/70/000000/sql.png'
    },
    {
        name:'MongoDB',
        icon:'https://img.icons8.com/color/70/000000/mongodb.png'
    },
    {
        name:'JSON',
        icon:'https://img.icons8.com/color/70/000000/json--v1.png'
    },
    {
        name:'GraphQL',
        icon:'https://img.icons8.com/color/70/000000/graphql.png'
    },
]

export const TF = [
    {
        name:'Figma',
        icon:'https://img.icons8.com/color/70/000000/figma.png'
    },
    {
        name:'VSC',
        icon:'https://img.icons8.com/color/70/000000/visual-studio-code-2019.png'
    },
    {
        name:'Heroku',
        icon:'https://img.icons8.com/color/70/000000/heroku.png'
    },
    {
        name:'Git',
        icon:'https://img.icons8.com/color/70/000000/git.png'
    },
    {
        name:'Github',
        icon:'https://img.icons8.com/color/70/000000/github--v1.png'
    },
]