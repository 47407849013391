export const experienceData = [
    {
        id: 11,
        year:'2024',
        yearRange: '2024-Present',
        name: 'Full Stack Engineer',
        institute: 'Self Employed',
        description: 'Collaborate with clients to design, develop, and deploy web applications. Create responsive front-end interfaces and robust back-end solutions. Proficient in HTML, CSS, JavaScript, Node.js, Express.js, and integrating APIs. Successfully completed multiple projects on time and within budget, ensuring client satisfaction.'
    },
    {
        id: 10,
        year:'2024',
        yearRange: '2023-Present',
        name: 'Software Engineer',
        institute: 'Reflectica AI',
        description: 'Lead developer at Reflectica AI, enhancing psychotherapy platform with React Native and Firebase. Achieved 20% improvement in application responsiveness. Developed full-stack web app with Node.js, RESTful APIs, and Redux. Managed product lifecycle, troubleshooting, and maintaining high-quality standards.'
    },
    {
        id: 9,
        year:'2023',
        yearRange: '2023-2024',
        name: 'Software Engineer Intern',
        institute: 'Reflectica AI',
        description: 'Contributed to Reflectica AI\'s psychotherapy platform development. Conducted thorough testing, participated in code reviews, and resolved software issues. Collaborated effectively with engineering team, ensuring platform stability and functionality.'
    },
    {
        id: 8,
        year:'2023',
        yearRange: '2023-Present',
        name: 'Coding Instructor',
        institute: 'CodeRev Kids',
        description: 'Taught programming fundamentals and video game development to students. Utilized tools like Pencil Code and Scratch. Introduced hands-on learning with Arduino for Snap Circuits Engineering. Enhanced students\' coding proficiency through expert guidance.'
    },
    {
        id: 7,
        year:'2023',
        yearRange: '2023-2024',
        name: 'Learning Assistant',
        institute: 'edX',
        description: 'Provided exceptional support to students in various programming languages and concepts. Fostered collaborative learning environment and adapted teaching methods to meet student needs. Built trust and engagement in virtual learning community.'
    },
    {
        id: 6,
        year:'2023',
        yearRange: '2023-Present',
        name: 'Founder and Administrator',
        institute: 'Programming Accountability Group',
        description: 'Founded and administered programming accountability group. Organized meetings, set goals, and provided support to members. Developed leadership and collaboration skills.'
    },
    {
        id: 5,
        year:'2023',
        yearRange: '2023-2023',
        name: 'Hackathon',
        institute: 'Bay Area Hacks 23',
        description: 'Developed MERN application with GraphQL and Material UI. Managed frontend and backend using React, Express, Node, and MongoDB. Delivered pitch presentation under time pressure.'
    },
    {
        id: 4,
        year:'2022',
        yearRange: '2022-2023',
        name: 'Leasing Consultant',
        institute: 'Asset Living',
        description: 'Increased social media interaction by 22.7% on TikTok and Instagram. Boosted Google rating from 1.2 to 3.1 stars. Managed move-in/move-out process for 70% of 750+ bed spaces. Provided exceptional customer service and resolved inquiries.'
    },
    {
        id: 3,
        year:'2022',
        yearRange: '2022-2022',
        name: 'Fullstack Coding Bootcamp',
        institute: 'University of Washington',
        description: 'Completed intensive 24-week full-stack coding bootcamp. Proficient in HTML, CSS, JavaScript, Git, and Agile methodology. Developed skills in server-side APIs, OOP, SQL, and React.'
    },
    {
        id: 2,
        year:'2021',
        yearRange: '2021-2022',
        name: 'Courier',
        institute: 'DoorDash/UberEats',
        description: 'Delivered orders on time with 99% satisfaction rate. Managed routes efficiently and maximized profit. Demonstrated reliability, professionalism, and problem-solving skills.'
    },
    {
        id: 1,
        year:'2020',
        yearRange: '2016-2020',
        name: 'Bachelor\'s Degree',
        institute: 'University of Washington',
        description: 'Graduated with BA in Anthropology. Gained skills in critical thinking, analysis, and collaboration. Developed interest in informatics and technology.'
    }
]